import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';
import useBodyClass from '../../hooks/body-class';
import './ListPage.css';
import { LoggedContext } from '../../contexts/LoggedContext';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import numberFormat from '../../helpers/numberFormat';
import Pagination from '../../components/Pagination/Pagination';
import modalManager from '../../services/modalManager';
import MenuDropdown from '../../components/Html/MenuDropdown';

export default function ListPage({ history }) {

  let query = new URLSearchParams(useLocation().search);
  const context = useContext(LoggedContext);
  const [isLoading, setIsLoading] = useState(false);
  const [ordens, setOrdens] = useState({
    data: [],
    current: 1,
    pages: null,
    total: 0
  });
  const filtroModel = {
    id: "",
    periodoPor: "entrega",
    periodoInicial: "",
    periodoFinal: "",
    cliente: "",
    ofCliente: "",
    referencia: "",
    marca: "",
    status: {
      aberta: false,
      producao: false,
      fechada: false,
      finalizada: false,
      cancelada: false
    },
    emAtraso: false
  };
  const [filtros, setFiltros] = useState({ 
    ...filtroModel,
    id: query.get("id") || filtroModel.id,
    periodoPor: query.get("periodoPor") || filtroModel.periodoPor,
    periodoInicial: query.get("periodoInicial") || filtroModel.periodoInicial,
    periodoFinal: query.get("periodoFinal") || filtroModel.periodoFinal,
    cliente: query.get("cliente") || filtroModel.cliente,
    ofCliente: query.get("ofCliente") || filtroModel.ofCliente,
    referencia: query.get("referencia") || filtroModel.referencia,
    marca: query.get("marca") || filtroModel.marca,
    status: {
      aberta: query.getAll('status[]').includes('aberta') ? true : false,
      producao: query.getAll('status[]').includes('producao') ? true : false,
      fechada: query.getAll('status[]').includes('fechada') ? true : false,
      finalizada: query.getAll('status[]').includes('finalizada') ? true : false,
      cancelada: query.getAll('status[]').includes('cancelada') ? true : false
    },
    emAtraso: query.get("emAtraso") || filtroModel.emAtraso,
  });
  const [maquinas, setMaquinas] = useState(maquinasToDropdown(context.maquinasCache));
  const [css, setCss] = useState('');

  useBodyClass("page-opestamparia");

  useEffect(() => {
    if (query.toString() === '') {
      resetaFiltro();
    }
    loadOrdens();
  }, [query.toString(), context.estamparia]);

  useEffect(() => {
    setMaquinas(maquinasToDropdown(context.maquinasCache));
  }, [context.maquinasCache]);

  async function loadOrdens() {
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/op-estamparia?` + query);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as ordens de producação. Por favor, tente novamente.");
      }
      return;
    }
    setOrdens(resp.data);
    setIsLoading(false);
  }

  function onSubmitFiltro(event) {
    event.preventDefault();
    const newQuery = new URLSearchParams();
    if (filtros.id) newQuery.set("id", filtros.id);
    if (filtros.periodoPor === 'entrega') {
      if (filtros.periodoInicial) newQuery.set("entregaInicial", filtros.periodoInicial);
      if (filtros.periodoFinal) newQuery.set("entregaFinal", filtros.periodoFinal);
    } else if (filtros.periodoPor === 'entrada') {
      if (filtros.periodoInicial) newQuery.set("entradaInicial", filtros.periodoInicial);
      if (filtros.periodoFinal) newQuery.set("entradaFinal", filtros.periodoFinal);
    } else if (filtros.periodoPor === 'previsao') {
      if (filtros.periodoInicial) newQuery.set("previsaoEntregaInicial", filtros.periodoInicial);
      if (filtros.periodoFinal) newQuery.set("previsaoEntregaFinal", filtros.periodoFinal);
    }
    if (filtros.cliente) newQuery.set("cliente", filtros.cliente);
    if (filtros.ofCliente) newQuery.set("ofCliente", filtros.ofCliente);
    if (filtros.referencia) newQuery.set("referencia", filtros.referencia);
    if (filtros.marca) newQuery.set("marca", filtros.marca);
    let maquinasEscolhidas = (maquinas||[]).filter(m => m.checked === true);
    if (maquinasEscolhidas.length > 0 && maquinasEscolhidas.length < maquinas.length) {
      newQuery.set("maquinas", maquinasEscolhidas.map(m => m.id));
    }
    if (filtros.status.aberta) newQuery.append("status[]", "aberta");
    if (filtros.status.producao) newQuery.append("status[]", "producao");
    if (filtros.status.fechada) newQuery.append("status[]", "fechada");
    if (filtros.status.finalizada) newQuery.append("status[]", "finalizada");
    if (filtros.status.cancelada) newQuery.append("status[]", "cancelada");
    if (filtros.emAtraso) newQuery.set("emAtraso", filtros.emAtraso);
    history.push('?' + newQuery);
  }

  function onClickLimparFiltro(event) {
    event.preventDefault();
    const url = new URL(window.location.href);
    history.push(url.pathname);
  }
  
  function resetaFiltro() {
    setMaquinas(maquinasToDropdown(context.maquinasCache));
    setFiltros({...filtroModel});
  }

  function maquinasToDropdown(maquinas) {
    if ( ! Array.isArray(maquinas)) return undefined; 
    let maquinasNoFiltro = false;
    if (query.has('maquinas')) {
      maquinasNoFiltro = query.get('maquinas').split(',');
      maquinasNoFiltro = maquinasNoFiltro.map(i => parseInt(i));
    }
    return maquinas.map(m => {
      return {
        id: m.id,
        checked: !maquinasNoFiltro ? true : maquinasNoFiltro.includes(m.id),
        label: m.nome
      }
    });
  }

  function showModalExportarCSV(event) {
    event.preventDefault();
    modalManager.mount(
      'modalExportarCSV',
      <ModalExportarCSV />
    );
    modalManager.show('modalExportarCSV');
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordens de Produção de Estampas</h2>

      <nav className="navbar navbar-expand navbar-light bg-light mb-3">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <button type="button" className="btn btn-primary" onClick={e => history.push('/op-estamparia/form')}>Cadastrar nova Ordem</button>
            </li>
          </ul>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Exportar
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item" onClick={showModalExportarCSV}>
                CSV (Excel)
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="container-fluid filtro-container d-print-none mb-5">
        <form onSubmit={onSubmitFiltro}>
          <div className="form-row mb-3">
            <div className="col-md-12">
              <span className="pr-3">Período:</span>
              <label className="mr-3">
                <input
                  type="radio"
                  name="periodoPor"
                  value="entrega"
                  checked={filtros.periodoPor === "entrega"}
                  onChange={(event) => {
                    filtros.periodoPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                /> Entrega
              </label>
              <label className="mr-3">
                <input
                  type="radio"
                  name="periodoPor"
                  value="previsao"
                  checked={filtros.periodoPor === "previsao"}
                  onChange={(event) => {
                    filtros.periodoPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                /> Previsão Entrega
              </label>
              <label className="mr-3">
                <input
                  type="radio"
                  name="periodoPor"
                  value="entrada"
                  checked={filtros.periodoPor === "entrada"}
                  onChange={(event) => {
                    filtros.periodoPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                /> Entrada
              </label>
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filtros.periodoInicial}
                onChange={e => {
                  filtros.periodoInicial = e.target.value;
                  setFiltros({ ...filtros });
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filtros.periodoFinal}
                onChange={e => {
                  filtros.periodoFinal = e.target.value;
                  setFiltros({ ...filtros });
                }}
              />
            </div>
            <div className="col-md-1">
              <input
                type="number"
                className="form-control"
                value={filtros.id}
                onChange={e => {
                  filtros.id = e.target.value;
                  setFiltros({ ...filtros });
                }}
                placeholder="ID"
                title="Id da OP"
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                value={filtros.cliente}
                onChange={e => {
                  filtros.cliente = e.target.value;
                  setFiltros({ ...filtros });
                }}
                placeholder="Cliente"
                title="Cliente"
              />
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={filtros.ofCliente}
                onChange={e => {
                  filtros.ofCliente = e.target.value;
                  setFiltros({ ...filtros });
                }}
                placeholder="O.F."
              />
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={filtros.referencia}
                onChange={e => {
                  filtros.referencia = e.target.value;
                  setFiltros({ ...filtros });
                }}
                placeholder="Referência"
                title="Referência"
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="form-control"
                value={filtros.marca}
                onChange={e => {
                  filtros.marca = e.target.value;
                  setFiltros({ ...filtros });
                }}
                placeholder="Marca"
                title="Marca"
              />
            </div>
            <div className="col-md-1">
              <MenuDropdown 
                title="Máquinas"
                values={maquinas}
                setValues={setMaquinas}
                itemClass="text-nowrap"
                btnClass="btn-secondary"
              />
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="col-md-12">
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroAberta" 
                  checked={filtros.status.aberta}
                  onChange={e => {
                    filtros.status.aberta = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroAberta">Aberta</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroProducao"
                  checked={filtros.status.producao}
                  onChange={e => {
                    filtros.status.producao = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroProducao">Produção</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroFechada"
                  checked={filtros.status.fechada}
                  onChange={e => {
                    filtros.status.fechada = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroFechada">Fechada</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroFinalizada"
                  checked={filtros.status.finalizada}
                  onChange={e => {
                    filtros.status.finalizada = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroFinalizada">Finalizada</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroCancelada"
                  checked={filtros.status.cancelada}
                  onChange={e => {
                    filtros.status.cancelada = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroCancelada">Cancelada</label>
              </div>
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroAtraso"
                  checked={filtros.emAtraso}
                  onChange={e => {
                    filtros.emAtraso = e.target.checked;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="filtroAtraso">Em Atraso</label>
              </div>
            </div>
          </div>
          <div className="form-group form-actions">
            <button type="button" className="btn btn-secondary btn-sm mr-3" onClick={onClickLimparFiltro}>Limpar</button>
            <button type="submit" className="btn btn-primary btn-sm">Filtrar</button>
          </div>
        </form>
      </div>

      <div className="d-print-none mb-3 text-right">
        <MenuDropdown 
          title="Colunas"
          staticValues={[
            {id: 'status', checked: true, label: 'Status'},
            {id: 'entrada', checked: true, label: 'Entrada'},
            {id: 'previsao', checked: true, label: 'Previsão de Entrega'},
            {id: 'entrega', checked: true, label: 'Entrega'},
            {id: 'cliente', checked: true, label: 'Cliente'},
            {id: 'of', checked: true, label: 'O.F.'},
            {id: 'marca', checked: true, label: 'Marca'},
            {id: 'referencia', checked: true, label: 'Referência'},
            {id: 'preco', checked: true, label: 'Preço Estampa'},
            {id: 'quantidade', checked: true, label: 'Quantidade'},
            {id: 'maquinas', checked: true, label: 'Máquinas'},
            {id: 'quantidadecores', checked: true, label: 'Qtd. Cores'},
            {id: 'quantidadequadros', checked: true, label: 'Qtd. Quadros'},
            {id: 'custocores', checked: true, label: 'Custo Cores p/ Peça'},
            {id: 'lead', checked: true, label: 'Lead Time'},
            {id: 'dispmaquina', checked: true, label: 'Disp. de Máquina'},
            {id: 'tempoproducao', checked: true, label: 'Tempo Produção'},
            {id: 'temposetup', checked: true, label: 'Tempo Setup'},
            {id: 'pecassegunda', checked: true, label: 'Peças de 2ª'},
            {id: 'tempoparadaprogramada', checked: true, label: 'Paradas Programadas'},
            {id: 'tempoparadanaoprogramada', checked: true, label: 'Paradas Não Programadas'},
            {id: 'pecashora', checked: true, label: 'Peças p/ Hora'},
            {id: 'faturamentohora', checked: true, label: 'Faturamento p/ Hora'},
            {id: 'faturamento', checked: true, label: 'Faturamento'}
          ]}
          onChange={values => {
            let css = '';
            values.map(v => {
              css += `#tabela-op .op-h-${v.id} { display: ${v.checked ? 'table-cell' : 'none'}; }`;
              css += `#tabela-op .op-${v.id} { display: ${v.checked ? 'table-cell' : 'none'}; }`;
            });
            setCss(css);
          }}
          itemClass="text-nowrap"
          btnClass="btn-secondary"
        />
      </div>

      <style>
        {css}
      </style>
      <div className="ordens-container">
        <div className="table-container-scroll mb-4">
          {isLoading &&
            <table className="table table-striped">
              <caption style={{ textAlign: 'center' }}>Odens de Produção</caption>
              <tbody>
                <tr style={{ padding: '20px', textAlign: 'center' }}>
                  <td>Carregando...</td>
                </tr>
              </tbody>
            </table>
          }
          {!isLoading && ordens.data.length == 0 &&
            <table className="table table-striped">
              <caption style={{ textAlign: 'center' }}>Odens de Produção</caption>
              <tbody>
                <tr style={{ padding: '20px', textAlign: 'center' }}>
                  <td>Não há Ordens de Produção cadastradas</td>
                </tr>
              </tbody>
            </table>
          }
          {!isLoading && ordens.data.length > 0 &&
            <table id="tabela-op" className="table table-striped table-sm">
              <thead className="sticky thead-dark">
                <tr>
                  <th scope="col" className="d-print-none">Ações</th>
                  <th scope="col" className="op-h-id">ID</th>
                  <th scope="col" className="op-h-status">Status</th>
                  <th scope="col" className="op-h-entrada">Entrada</th>
                  <th scope="col" className="op-h-previsao">Previsão Entrega</th>
                  <th scope="col" className="op-h-entrega">Entrega</th>
                  <th scope="col" className="op-h-cliente">Cliente</th>
                  <th scope="col" className="op-h-of">O.F.</th>
                  <th scope="col" className="op-h-marca">Marca</th>
                  <th scope="col" className="op-h-referencia">Ref.</th>
                  <th scope="col" className="op-h-preco">Preço Estampa</th>
                  <th scope="col" className="op-h-quantidade">Quantidade</th>
                  <th scope="col" className="op-h-maquinas">Máquinas</th>
                  <th scope="col" className="op-h-quantidadecores">Qtd. Cores</th>
                  <th scope="col" className="op-h-quantidadequadros">Qtd. Quadros</th>
                  <th scope="col" className="op-h-custocores">Custo Cores p/ Peça</th>
                  <th scope="col" className="op-h-lead">Lead Time</th>
                  <th scope="col" className="op-h-dispmaquina">Disponibilidade de Máquina</th>
                  <th scope="col" className="op-h-tempoproducao">Tempo Produção</th>
                  <th scope="col" className="op-h-temposetup">Tempo Setup</th>
                  <th scope="col" className="op-h-pecassegunda">Peças de 2ª</th>
                  <th scope="col" className="op-h-tempoparadaprogramada">Paradas Programadas</th>
                  <th scope="col" className="op-h-tempoparadanaoprogramada">Paradas Não Programadas</th>
                  <th scope="col" className="op-h-pecashora">Peças p/ Hora</th>
                  <th scope="col" className="op-h-faturamentohora">Faturamento p/ Hora</th>
                  <th scope="col" className="op-h-faturamento">Faturamento</th>
                </tr>
              </thead>
              <tbody>
                {ordens.data.map((ordem, index) => (
                  <tr key={index}>
                    <td className="sticky d-print-none">
                      <Link className="btn btn-outline-secondary mr-1" to={location => `${location.pathname}/form/${ordem.id}`}>
                        <i className="icofont-ui-edit"></i>
                      </Link>
                      <Link className="btn btn-outline-secondary" to={location => `${location.pathname}/${ordem.id}/print`}>
                        <i className="icofont-printer"></i>
                      </Link>
                    </td>
                    <td className="op-id">{ordem.id}</td>
                    <td className="op-status">
                      <span className={`statusop-${ordem.status}-color`}>
                        <strong>{ordem.status}</strong>
                      </span>
                    </td>
                    <td className="op-entrada">{ordem.entrada ? moment(ordem.entrada).format('DD/MM/YYYY') : ''}</td>
                    <td className="op-previsao">{ordem.previsaoEntrega ? moment(ordem.previsaoEntrega).format('DD/MM/YYYY') : ''}</td>
                    <td className="op-entrega">{ordem.entrega ? moment(ordem.entrega).format('DD/MM/YYYY') : (ordem.previsaoEntrega && (new Date(ordem.previsaoEntrega.slice(0,10)+'T23:59:59') < new Date()) ? <span style={{color:'red'}}>Em Atraso</span>:'')}</td>
                    <td className="op-cliente">{ordem.cliente ? ordem.cliente.nome : ''}</td>
                    <td className="op-of">{ordem.ofCliente}</td>
                    <td className="op-marca">{ordem.marca}</td>
                    <td className="op-referencia">{ordem.referencia}</td>
                    <td className="op-preco">{numberFormat(ordem.precoEstampa)}</td>
                    <td className="op-quantidade">{ordem.quantidade}</td>
                    <td className="op-maquinas">{ordem.maquinas.map(maq => maq.nome).join(', ')}</td>
                    <td className="op-quantidadecores">{ordem.quantidadeCores}</td>
                    <td className="op-quantidadequadros">{ordem.quantidadeQuadros}</td>
                    <td className="op-custocores">{numberFormat(ordem.custoCores / (ordem.quantidade || 1))}</td>
                    <td className="op-lead">{numberFormat(ordem.leadTime)} dias</td>
                    <td className="op-dispmaquina">{ordem.tempoDisponibilidadeMaquina}</td>
                    <td className="op-tempoproducao">{ordem.tempoProducao}</td>
                    <td className="op-temposetup">{ordem.tempoSetup}</td>
                    <td className="op-pecassegunda">{ordem.pecasSegundaQualidadeQuantidade}</td>
                    <td className="op-tempoparadaprogramada">{ordem.tempoParadaProgramada}</td>
                    <td className="op-tempoparadanaoprogramada">{ordem.tempoParadaNaoProgramada}</td>
                    <td className="op-pecashora">{ordem.pecasPorHoraTrabalho}</td>
                    <td className="op-faturamentohora">{numberFormat(ordem.faturamentoPorHoraTrabalho)}</td>
                    <td className="op-faturamento">{numberFormat(ordem.faturamento)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
        {ordens.data.length > 0 && <Pagination totalPages={ordens.pages} />}
      </div>
    </LayoutLogged>
  );
}

function ModalExportarCSV() {

  const [exporting, setExporting] = useState(false);

  function exportarCSV() {
    setExporting(true);
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('content_type', 'csv');
    api.get(`/op-estamparia?` + searchParams).then(resp => {
      let filename = resp.headers['content-disposition'].split('filename="')[1];
      filename = filename.substr(0, filename.length - 2);
      const url = window.URL.createObjectURL(new Blob([decodeURIComponent('%ef%bb%bf') /*prepend bom*/, resp.data], { type: 'text/csv;charset=utf-8'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(error => {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível exportar as Ordens de Producação. Por favor, tente novamente.");
      }
    }).then(() => {
      modalManager.hide();
      setExporting(false);
    });
  }

  return (
    <div className="modal fade" id="modalExportarCSV" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Exportar OPs para CSV</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <strong>ATENÇÃO:</strong>
            <ul>
              <li>o conteúdo do CSV será de acordo com o filtro que você aplicou na lista das Ordens de Produção;</li>
              <li>é obrigatório escolher o Período de Entrada OU o Período de Entrega das Ordens de Produção;</li>
              <li>o Período não pode ser maior que 1 ano;</li>
            </ul>
            <div className="btn-group" role="group">
              <button type="button" disabled={!!exporting} className="btn btn-primary" onClick={exportarCSV}>
                {exporting ? 'Exportando...' : 'Exportar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}