import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import './SearchForm.css';

export default function SearchForm({ clientes, maquinas, filtrosDefault, ...props }) {

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [submitCount, setSubmitCount] = useState(0);
  const [metadados, setMetadados] = useState({
    relatorio: 'producao',
    relatorioTitulo: 'Produção',
    filtrosDescricao: '',
  });
  const filtroModel = {
    classificarPor: 'cliente',
    agruparPor: 'mes',
    periodoInicial: moment().subtract(31, 'days').format('YYYY-MM-DD'),
    periodoFinal: moment().format('YYYY-MM-DD'),
    entradaInicial: '',
    entradaFinal: '',
    clientesIds: (() => retornaIdsDosClientes(clientes))(),
    maquinasIds: (() => retornaIdsDasMaquinas(maquinas))(),
    entregaInicial: moment().subtract(31, 'days').format('YYYY-MM-DD'),
    entregaFinal: moment().format('YYYY-MM-DD'),
    ...filtrosDefault
  };
  const [filtros, setFiltros] = useState({
    ...filtroModel
  });

  useEffect(() => {
    if (clientes) {
      preencheOsFiltrosComOsParametrosDaUrl();
    }
  }, [query.toString(), clientes, submitCount]);

  useEffect(() => {
    if (metadados.relatorio !== "eficienciaOperacional" 
      && filtros.classificarPor === 'operador') {
        filtros.classificarPor = 'cliente';
        setFiltros({ ...filtros });
    }
  }, [metadados]);

  function onSubmitFiltro(event) {
    event.preventDefault();
    if (!validaFiltro()) {
      return false;
    }
    const url = new URL(window.location.href);
    url.searchParams.delete('page');
    if (metadados.relatorio === 'producao') {
      url.searchParams.set('periodoInicial', filtros.periodoInicial);
      url.searchParams.set('periodoFinal', filtros.periodoFinal);
    } else {
      for (let filtro in filtros) {
        url.searchParams.set(filtro, filtros[filtro]);
      }
    }
    history.replace(url.search);
    setSubmitCount(submitCount + 1);
  }

  function validaFiltro() {
    if (filtros.entregaInicial === ""
        || filtros.entregaFinal === "") {
      alert('Preencha a data de Entrega da OP.');
      return false;
    }
    return true;
  }

  function preencheOsFiltrosComOsParametrosDaUrl() {
    let novoFiltro = { ...filtros };
    const url = new URL(window.location.href);
    if (!url.search) {
      setFiltros({ ...filtroModel });
      //props.onApply({ ...filtroModel });
      return;
    }
    Object.entries(filtroModel).map(entry => {
      if (url.searchParams.has(entry[0])) {
        if(entry[0] == 'clientesIds' || entry[0] == 'maquinasIds') {
          let ids = url.searchParams.get(entry[0]).split(',').filter(el => el != "");
          ids = (ids || []).map(id => parseInt(id));
          novoFiltro[entry[0]] = ids;
        } else {
          novoFiltro[entry[0]] = url.searchParams.get(entry[0]);
        }
      }
    });
    metadados.filtrosDescricao = filtrosToString(novoFiltro);
    setMetadados({...metadados });
    setFiltros({ ...novoFiltro });
    props.onApply({ ...novoFiltro }, metadados);
  }

  function retornaIdsDosClientes(clientes) {
    let ids = [];
    (clientes || []).map(cliente => ids.push(cliente.id));
    return ids;
  }

  function retornaIdsDasMaquinas(maquinas) {
    let ids = [];
    (maquinas || []).map(maquina => ids.push(maquina.id));
    return ids;
  }

  function filtrosToString(filtros) {
    let entrada = '';
    let entrega = '';
    if (filtros.entradaInicial || filtros.entradaFinal) {
      entrada = `Entrada: ${filtros.entradaInicial && moment(filtros.entradaInicial).format('DD/MM/YYYY')} - ${filtros.entradaFinal && moment(filtros.entradaFinal).format('DD/MM/YYYY')}. `;
    }
    if (filtros.entregaInicial || filtros.entregaFinal) {
      entrega = `Entrega: ${filtros.entregaInicial && moment(filtros.entregaInicial).format('DD/MM/YYYY')} - ${filtros.entregaFinal && moment(filtros.entregaFinal).format('DD/MM/YYYY')}. `;
    }
    return entrada + entrega;
  }

  function concatenaNomeDosClientes(clientesIds) {
    let nomesArr = [];
    clientes.map(cliente => {
      if(clientesIds.indexOf(cliente.id) > -1) {
        nomesArr.push(cliente.nome);
      }
    });
    return nomesArr.join(', ');
  }

  return (
    <div className="dashboard-filtro-container d-print-none">
      <form onSubmit={onSubmitFiltro}>
        <div className="btn-group tipo-relatorio">
          <label className={`btn ${metadados.relatorio == 'producao' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="producao"
              checked={metadados.relatorio === "producao"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Produção';
                setMetadados({ ...metadados });
              }}
            /> Produção
          </label>
          <label className={`btn ${metadados.relatorio == 'faturamento' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="faturamento"
              checked={metadados.relatorio === "faturamento"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Faturamento';
                setMetadados({ ...metadados });
              }}
            /> Faturamento
          </label>
          <label className={`btn ${metadados.relatorio == 'ticketMedio' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="ticketMedio"
              checked={metadados.relatorio === "ticketMedio"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Ticket Médio';
                setMetadados({ ...metadados });
              }}
            /> Ticket Médio
          </label>
          <label className={`btn ${metadados.relatorio == 'rentabilidade' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="rentabilidade"
              checked={metadados.relatorio === "rentabilidade"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Rentabilidade';
                setMetadados({ ...metadados });
              }}
            /> Rentabilidade
          </label>
          <label className={`btn ${metadados.relatorio == 'produtividade' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="produtividade"
              checked={metadados.relatorio === "produtividade"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Produtividade';
                setMetadados({ ...metadados });
              }}
            /> Produtividade
          </label>
          <label className={`btn ${metadados.relatorio == 'eficienciaOperacional' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="eficienciaOperacional"
              checked={metadados.relatorio === "eficienciaOperacional"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Eficiência Operacional';
                setMetadados({ ...metadados });
              }}
            /> Eficiência Operacional
          </label>
          <label className={`btn ${metadados.relatorio == 'setupPorQuadro' ? 'active' : ''}`}>
            <input
              type="radio"
              name="relatorio"
              value="setupPorQuadro"
              checked={metadados.relatorio === "setupPorQuadro"}
              onChange={(event) => {
                metadados.relatorio = event.target.value;
                metadados.relatorioTitulo = 'Setup por Quadro';
                setMetadados({ ...metadados });
              }}
            /> Setup por Quadro
          </label>
        </div>
        {['producao','setupPorQuadro'].includes(metadados.relatorio) && 
          <div className="form-row">
            <div className="form-group col-md-4">
              <div className="form-row">
                <div className="label col-md-12">
                  Período de Entrega das OPs
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      value={filtros.periodoInicial}
                      onChange={e => {
                        filtros.periodoInicial = e.target.value;
                        setFiltros({ ...filtros });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      value={filtros.periodoFinal}
                      onChange={e => {
                        filtros.periodoFinal = e.target.value;
                        setFiltros({ ...filtros });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-md-2 form-actions">
              <button type="submit" className="btn btn-primary">Filtrar</button>
            </div>
          </div>
        }

        {!clientes && 'Aguardando lista de clientes...' }

        {clientes && !['producao', 'setupPorQuadro'].includes(metadados.relatorio) && 
          <div className="form-row">
            <div className="form-group col-md-2">
              <div className="label">Classificar por:</div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="classificarPor"
                  id="dashboardFiltroIndexByCliente"
                  value="cliente"
                  checked={filtros.classificarPor === "cliente"}
                  onChange={(event) => {
                    filtros.classificarPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="dashboardFiltroIndexByCliente">
                  Cliente
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="classificarPor"
                  id="dashboardFiltroIndexByMaquina"
                  value="maquina"
                  checked={filtros.classificarPor === "maquina"}
                  onChange={(event) => {
                    filtros.classificarPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="dashboardFiltroIndexByMaquina">
                  Máquina
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="classificarPor"
                  id="dashboardFiltroIndexByOperador"
                  value="operador"
                  checked={filtros.classificarPor === "operador"}
                  onChange={(event) => {
                    filtros.classificarPor = event.target.value;
                    setFiltros({ ...filtros });
                  }}
                  disabled={metadados.relatorio === 'eficienciaOperacional' ? false : true}
                />
                <label className="form-check-label" htmlFor="dashboardFiltroIndexByOperador">
                  Operador
                </label>
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agruparPor"
                  id="dashboardFiltroAgruparPorMes"
                  value="mes"
                  checked={filtros.agruparPor === "mes"}
                  onChange={(event) => {
                    filtros.agruparPor = event.target.checked ? event.target.value : '';
                    setFiltros({ ...filtros });
                  }}
                />
                <label className="form-check-label" htmlFor="dashboardFiltroAgruparPorMes">
                  Agrupar por Mês
                </label>
              </div>
            </div>
            {filtros.classificarPor == 'cliente' && 
              <div className="form-group col-md-3 clientes">
                <div className="label">
                  Clientes
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={(event) => {
                      filtros.clientesIds = retornaIdsDosClientes(clientes);
                      setFiltros({ ...filtros });
                    }}>Todos</button>|
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={(event) => {
                      filtros.clientesIds = [];
                      setFiltros({ ...filtros });
                    }}>Nenhum</button>
                </div>
                <div className="form-group">
                  {(clientes || []).map((cliente, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={cliente.id}
                        id={`dashboardFiltroCliente${cliente.id}`}
                        checked={filtros.clientesIds.indexOf(cliente.id) > -1 ? true : false}
                        onChange={(event) => {
                          event.target.checked ?
                            filtros.clientesIds.push(parseInt(event.target.value)) :
                            filtros.clientesIds.splice(filtros.clientesIds.indexOf(parseInt(event.target.value)), 1);
                          setFiltros({ ...filtros });
                        }}
                      />
                      <label className="form-check-label" htmlFor={`dashboardFiltroCliente${cliente.id}`}>{cliente.nome}</label>
                    </div>
                  ))}
                </div>
              </div>
            }
            {filtros.classificarPor == 'maquina' && 
              <div className="form-group col-md-3 clientes">
                <div className="label">
                  Máquinas
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={(event) => {
                      filtros.maquinasIds = retornaIdsDosClientes(maquinas);
                      setFiltros({ ...filtros });
                    }}>Todos</button>|
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={(event) => {
                      filtros.maquinasIds = [];
                      setFiltros({ ...filtros });
                    }}>Nenhum</button>
                </div>
                <div className="form-group">
                  {(maquinas || []).map((maquina, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={maquina.id}
                        id={`dashboardFiltroMaquina${maquina.id}`}
                        checked={filtros.maquinasIds.indexOf(maquina.id) > -1 ? true : false}
                        onChange={(event) => {
                          event.target.checked ?
                            filtros.maquinasIds.push(parseInt(event.target.value)) :
                            filtros.maquinasIds.splice(filtros.maquinasIds.indexOf(parseInt(event.target.value)), 1);
                          setFiltros({ ...filtros });
                        }}
                      />
                      <label className="form-check-label" htmlFor={`dashboardFiltroMaquina${maquina.id}`}>{maquina.nome}</label>
                    </div>
                  ))}
                </div>
              </div>
            }
            {filtros.classificarPor == 'operador' && 
              <div className="form-group col-md-3 clientes"></div>
            }
            <div className="form-group col-md-3">
              <div className="label">Entrada - escolha o período</div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  value={filtros.entradaInicial}
                  onChange={e => {
                    filtros.entradaInicial = e.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  value={filtros.entradaFinal}
                  onChange={e => {
                    filtros.entradaFinal = e.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <div className="label">Entrega - escolha o período</div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  value={filtros.entregaInicial}
                  onChange={e => {
                    filtros.entregaInicial = e.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  value={filtros.entregaFinal}
                  onChange={e => {
                    filtros.entregaFinal = e.target.value;
                    setFiltros({ ...filtros });
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-2 form-actions">
              <button type="submit" className="btn btn-primary">Filtrar</button>
            </div>
          </div>
        }
      </form>
    </div>
  );
}